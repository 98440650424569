<template>
  <div>
    <h4>รายการคำร้องเรียน</h4>
    <hr>

    <div class="row mb-2">
      <div class="col-12 col-sm-2">
        <b-form-group
          label="ค้นหา"
          label-for="vi-first-name"
        >
          <b-form-input
            v-model="options.search"
            placeholder="ค้นหา"
            type="text"
            class="d-inline-block mr-1"
          />
        </b-form-group>
      </div>
      <div class="col-12 col-sm-5">
        <div class="row">
          <div class="col-12 col-sm-6">
            <b-form-group
              label="เริ่มต้น"
              label-for="vi-first-name"
            >
              <DatePicker
                :date="options.dateStart"
                :type="'start'"
                :status="false"
                @setDatePicker="onSetDatePicker"
              />
            </b-form-group>
          </div>
          <div class="col-12 col-sm-6">
            <b-form-group
              label="
            สิ้นสุด"
              label-for="vi-first-name"
            >
              <DatePicker
                :date="options.dateEnd"
                :type="'end'"
                :status="false"
                @setDatePicker="onSetDatePicker"
              />
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-5">
        <div class="row">
          <div class="col-12 col-sm-6">
            <b-form-group
              label="ประเภท"
              label-for="vi-first-name"
            >
              <v-select
                v-model="options.type"
                multiple
                label="title"
                :options="option"
              />
            </b-form-group>
          </div>
          <div class="col-12 col-sm-6">
            <div class="d-flex">
              <b-form-group
                class="w-100"
                label="สถานะ"
                label-for="vi-first-name"
              >
                <b-form-select
                  v-model="options.status"
                  :options="optionsStatus"
                />
              </b-form-group>
              <b-button
                style="width: 50px"
                variant="danger"
                class="mb-1 align-self-end ml-1 btn-icon"
                @click="confirmDelete"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-table
      striped
      hover
      responsive
      class="type-relative table-custom"
      :items="data"
      :fields="fields"
      show-empty
      @filtered="onFiltered"
    >
      <template #head(checkbox)>
        <div class="d-flex justify-content-center">
          <b-form-checkbox v-model="checkbox" />
        </div>
      </template>
      <template #cell(checkbox)="data">
        <div class="d-flex justify-content-center">
          <b-form-checkbox
            v-model="data.item.checkbox"
            @change="setDelete(data.index)"
          />
        </div>
      </template>
      <template #cell(index)="data">
        {{
          ((options.currentPage - 1) * options.perPage + data.item.index)
            | toAmount
        }}
      </template>
      <template #cell(topic)="data">
        {{ data.item.title }}
      </template>
      <template #cell(username)="data">
        {{ data.item.user.profileName }} (<a
          v-if="data.item.phone"
          :href="`tel:${data.item.phone}`"
        >{{ data.item.phone }}</a> <span v-else>ไม่พบหมายเลขโทรศัพท์</span>)
      </template>
      <template #cell(fulltime)="data">
        {{ data.item.createdAt | toTimeThai }}
      </template>
      <template #cell(type)="data">
        {{ data.item.requestType.name }}
      </template>
      <template #cell(status)="data">
        <b-badge
          :variant="
            data.item.status === 0
              ? 'warning'
              : data.item.status === 1
                ? 'info'
                : 'success'
          "
        >{{
          data.item.status === 0
            ? "รอดำเนินการ"
            : data.item.status === 1
              ? "กำลังดำเนินการ"
              : "เสร็จสิ้น"
        }}</b-badge>
      </template>
      <template #cell(button)="data">
        <div class="d-flex justify-content-center align-items-center">
          <b-button
            variant="secondary"
            size="sm"
            @click="onCkeckByUser(data.item._id, 'Edit')"
          ><feather-icon :icon="`EditIcon`" /></b-button>
          <feather-icon
            :icon="`MoreVerticalIcon`"
            @click="onCkeckByUser(data.item._id, 'Detail')"
          />
        </div>
      </template>
      <template #empty>
        <div class="text-center m-1">
          <h4>ไม่พบข้อมูลรายการร้องเรียน</h4>
        </div>
      </template>
      <template #emptyfiltered>
        <div class="text-center m-1">
          <h4>ไม่พบข้อมูลรายการร้องเรียน</h4>
        </div>
      </template>
    </b-table>
    <b-card-body
      v-if="totalRow > 0"
      class="d-flex justify-content-between flex-wrap pt-0 mt-2"
    >
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="options.perPage"
          size="sm"
          inline
          :options="options.pageOptions"
        />
      </b-form-group>

      <div>
        <b-pagination
          v-model="options.currentPage"
          :total-rows="totalRow"
          :per-page="options.perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BPagination,
  BCardBody,
  BTable,
  BFormCheckbox,
  BBadge,
} from 'bootstrap-vue'

import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import FeatherIcon from '../../@core/components/feather-icon/FeatherIcon.vue'
import DatePicker from '../component/datePicker.vue'

export default defineComponent({
  components: {
    DatePicker,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BPagination,
    BCardBody,
    BTable,
    BFormCheckbox,
    BBadge,
    FeatherIcon,
    vSelect,
  },
  data() {
    return {
      options: {
        dateStart: moment().subtract(3, 'days').format('YYYY-MM-DD'),
        dateEnd: moment().format('YYYY-MM-DD'),
        search: '',
        status: '',
        perPage: 10,
        currentPage: 1,
        pageOptions: [10, 25, 50, 100],
        type: [],
      },
      totalRow: 10,
      data: [],
      checkbox: false,
      option: [],
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'bg-primary text-white text-center',
          thStyle: 'min-width:120px',
          tdClass: 'text-center',
        },
        {
          key: 'index',
          label: 'ลำดับ',
          thClass: 'bg-primary text-white text-center',
          thStyle: 'min-width:120px',
          tdClass: 'text-center',
        },
        {
          key: 'topic',
          label: 'หัวเรื่อง',
          thClass: 'bg-primary text-white text-center',
          thStyle: 'min-width:120px',
        },
        {
          key: 'username',
          label: 'ผู้ร้องเรียน',
          thClass: 'bg-primary text-white text-center',
          thStyle: 'min-width:130px',
        },
        {
          key: 'fulltime',
          label: 'วันที่',
          thClass: 'bg-primary text-white text-center',
          thStyle: 'min-width:130px',
          // thStyle: 'min-width:150px',
        },
        {
          key: 'type',
          label: 'ประเภท',
          thClass: 'bg-primary text-white text-center',
          thStyle: 'min-width:130px',
          tdClass: 'td-padding text-center',

          // thStyle: 'min-width:150px',
        },
        {
          key: 'status',
          label: 'สถานะ',
          thClass: 'bg-primary text-white text-center',
          thStyle: 'min-width:100px',
          tdClass: 'td-padding text-center',
        },
        {
          key: 'button',
          label: 'จัดการ',
          thClass: 'bg-primary text-white text-center',
          tdClass: 'td-padding',
          thStyle: 'width:180px',
        },
      ],
      optionsStatus: [
        { text: '', value: '' },
        { text: 'รอดำเนินการ', value: '0' },
        { text: 'กำลังดำเนินการ', value: '1' },
        { text: 'เสร็จสิ้น', value: '2' },
      ],
      dataDelete: [],
    }
  },
  setup() {},
  created() {
    this.loadType()
    this.onLoadList()
  },
  watch: {
    options: {
      handler() {
        this.onLoadList()
      },
      deep: true,
    },
    data: {
      handler() {},
      deep: true,
    },
    checkbox: {
      handler(val) {
        for (let l = 0; l < this.data.length; l++) {
          this.data[l].checkbox = val
          this.setDelete(l)
        }
      },
    },
  },
  methods: {
    onCkeckByUser(id, type) {
      this.$router.push(`management/${id}/${type}`)
    },
    setDelete(index) {
      if (this.data[index].checkbox) {
        const check = this.dataDelete.find(x => x === this.data[index]._id)
        if (check) return
        this.dataDelete.push(this.data[index]._id)
      } else {
        this.dataDelete = this.dataDelete.filter(
          x => x !== this.data[index]._id,
        )
      }
    },
    async loadType() {
      const res = await this.$store.dispatch('request/getRequestType')
      this.option = res.data.items.map(x => ({ title: x.name, val: x._id }))
    },
    async onLoadList() {
      try {
        const res = await this.$store.dispatch('request/getListRequest', {
          options: this.options,
        })

        this.data = res.data.items.docs.map((x, index) => ({
          ...x,
          ...{
            index: index + 1,
            checkbox: this.dataDelete.some(r => r === x._id),
          },
        }))
        this.totalRow = res.data.items.totalDocs
      } catch (error) {}
    },
    onSetDatePicker(data) {
      if (data.type === 'start') { this.options.dateStart = moment(data.val).format('YYYY-MM-DD') } else this.options.dateEnd = moment(data.val).format('YYYY-MM-DD')
    },
    onFiltered(FilterItem) {
      this.totalRow = FilterItem.length
      this.options.currentPage = 1
    }, // comfirm button color
    confirmDelete() {
      this.$swal({
        title: 'คุณแน่ใจ?',
        text: 'คุณต้องการลบรายการคำร้องเรียน',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        console.log(this.dataDelete)
        if (result.value) {
          const res = await this.$store.dispatch('request/deleteRequest', {
            listRequest: this.dataDelete,
          })
          if (res.data.success) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'คำร้อง',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'ลบสำเร็จ',
              },
            })
            this.dataDelete = []
            this.onLoadList()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'คำร้อง',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: 'ลบไม่สำเร็จ',
              },
            })
          }
        }
      })
    },
  },
})
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
