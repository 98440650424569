<template>
  <div>
    <div class="row match-height">
      <div class="col-md-3 col-lg-2 col-6">
        <b-card>
          <div class="d-flex align-items-center justify-content-center h-100">
            <div>
              <div class="total bg-light-primary m-auto d-flex justify-content-center">
                <feather-icon
                  icon="EyeIcon"
                  class="text-primary total-user-icon align-self-center"
                />
              </div>
              <p class="mt-2 mb-2 h3 text-center">
                {{ total.all | toAmount }}
              </p>
              <p class="text-muted text-center h5">
                ทั้งหมด
              </p>
            </div>
          </div>
        </b-card>
      </div>
      <div class="col-md-3 col-lg-2 col-6">
        <b-card>
          <div class="d-flex align-items-center justify-content-center h-100">
            <div>
              <div class="total bg-light-warning m-auto d-flex justify-content-center">
                <feather-icon
                  icon="HeartIcon"
                  class="text-warning total-user-icon align-self-center"
                />
              </div>
              <p class="mt-2 mb-2 h3 text-center">
                {{ total.waiting | toAmount }}
              </p>
              <p class="text-muted text-center h5">
                รอดำเนินการ
              </p>
            </div>
          </div>
        </b-card>
      </div>
      <div class="col-md-3 col-lg-2 col-6">
        <b-card>
          <div class="d-flex align-items-center justify-content-center h-100">
            <div>
              <div class="total bg-light-info m-auto d-flex justify-content-center">
                <feather-icon
                  icon="SendIcon"
                  class="text-info total-user-icon align-self-center"
                />
              </div>
              <p class="mt-2 mb-1 h3 text-center">
                {{ total.processing | toAmount }}
              </p>
              <p class="text-muted text-center h5">
                กำลังดำเนินการ
              </p>
            </div>
          </div>
        </b-card>
      </div>
      <div class="col-md-3 col-lg-2 col-6">
        <b-card>
          <div class="d-flex align-items-center justify-content-center h-100">
            <div>
              <div class="total bg-light-success m-auto d-flex justify-content-center">
                <feather-icon
                  icon="CheckCircleIcon"
                  class="text-success total-user-icon align-self-center"
                />
              </div>
              <p class="mt-2 mb-2 h3 text-center">
                {{ total.success | toAmount }}
              </p>
              <p class="text-muted text-center h5">
                เสร็จสิ้น
              </p>
            </div>
          </div>
        </b-card>
      </div>
      <div class="col-md-12 col-lg-4">
        <b-card class="align-items-center">
          <!-- <p class="mt-1 "></p> -->
          <div v-if="loading">
            <vue-apex-charts
              ref="chart"
              type="pie"
              :options="chartOptions"
              :series="series"
            />
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: 'pie',
          width: 300,
        },
        legend: {
          position: 'bottom',
        },
        labels: [],
        responsive: [
          {
            breakpoint: 1024,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: 'bottom',
              },
            },
          }],
        noData: {
          text: 'ไม่พบข้อมูล',
        },
      },
      total: {
        all: 0,
        waiting: 0,
        processing: 0,
        success: 0,
      },
      loading: false,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      try {
        const AllRequest = await this.$store.dispatch('request/getAllRequest')
        this.total.all = AllRequest.data.items.length
        const result = AllRequest.data.items.map(x => {
          if (x.status === 0) {
            this.total.waiting += 1
          } else if (x.status === 1) {
            this.total.processing += 1
          } else if (x.status === 2) {
            this.total.success += 1
          }
          return true
        })
        await Promise.all(result)
        const resultRequest = await AllRequest.data.items.reduce((type, item) => {
          // eslint-disable-next-line no-param-reassign
          type[item.requestType.name] = type[item.requestType.name] || 0
          // eslint-disable-next-line no-param-reassign
          type[item.requestType.name] += 1
          return type
        }, {})
        this.series = Object.values(resultRequest)
        this.chartOptions.labels = Object.keys(resultRequest)
        this.loading = true
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped>
.total {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.total-user-icon {
  width: 30px;
  height: 30px;
}
</style>
