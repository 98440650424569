<template>
  <div class="container-fluid">
    <cardTotal />
    <div class="card">
      <div class="card-body">
        <listRequest />
      </div>
    </div>
  </div>
</template>

<script>
// import { BCard, BCardText, BLink } from 'bootstrap-vue'
// import liff from '@line/liff'
import { defineComponent } from '@vue/composition-api'
import cardTotal from './request/cardTotal.vue'
import listRequest from './request/list-request.vue'

export default defineComponent({
  components: {
    listRequest,
    cardTotal,
  },
  data() {
    return {
      profile: undefined,
    }
  },
})
</script>

<style></style>
